<template>
	<div>
		<NavBar style="max-height: 50px;margin-bottom: 10px;" :username="currentUserName"></NavBar>
    <v-app>
      <div style="background-color: #f5faff; height: 93vh !important;">
         <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height=50px;" :pageName="pageName"></SideBar>
        </v-col>
          <v-col cols="10">

             <h3 style="text-align:left; margin-left:65px; margin-top:30px;font-weight:800; font-size: 12pt;
                text-transform: uppercase;   letter-spacing: 1px;">Edit Specialization</h3>

            <v-card style="padding:10px; margin:20px 50px; height: 70vh; overflow: overflow:scroll; overflow-x: hidden;">

           <div style="margin: 20px 50px;">
            <div style='display: flex; flex-direction: column;'>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 800" align="left">
                  <span class="css-title"> Specialization Name</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Name"
                    solo
                    v-model="specialization_name"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 800" align="left">
                  <span class="css-title"> Specialization Name (Burmese)</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Name"
                    solo
                    v-model="specialization_name_mm"
                  ></v-text-field>
                </div>
              </div>


              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 800" align="left">
                  <span class="css-title"> Description</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-textarea
                    label="Enter Description in English"
                    solo
                    v-model="short_description"
                  ></v-textarea>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 800" align="left">
                  <span class="css-title"> Description (Burmese)</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-textarea
                    label="Enter Description in Burmese"
                    solo
                    v-model="short_description_mm"
                  ></v-textarea>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 800" align="left">
                  <span class="css-title"> Specialization Position</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Enter position"
                    solo
                    v-model="spec_position"
                    type="number"
                  ></v-text-field>
                </div>
              </div>



              <div>
                <v-btn style="width:50%;" color="primary" @click="editSpecialization" :disabled="isSpecialisationClicked || !(specialization_name_mm !='' && specialization_name != '' && short_description.trim() != '' && short_description_mm.trim() != '' && spec_position && parseInt(spec_position) >= 0)">Apply Changes</v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</v-app>

</div>
</template>


<script>

import axios from "axios";
import NavBar from '../../../views/navbar.vue';
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
  name: 'CustomerHome',
  data() {
  	return {
  	  specialization_name: "",
      old_name: "",
      specialization_name_mm : "",
      currentUser: "",
      currentUserName: "",
      user_id: "",
      phone_number: "",
      pageName: "editSpecialization",
      specialist_array: [],
      selected_specialization: "",
      short_description: "",
      short_description_mm : "",
      specializationId:"",
      isSpecialisationClicked : false,
      spec_position: null
    }
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if(!this.currentUser){
      this.$router.push({
        name: "AdminLogin",
      });
    }
    else{
      var params = this.$route.params;
      var specialization_id = params['specialization_id'];
      this.specializationId = specialization_id;

      var data = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        specializationId : this.specializationId,
      };
      axios_auth_instance_admin.post("/adminEditSpecializationPage", data)
      .then((successResponse) => {
        // var response_data = successResponse.data.data;
        this.specialization_name = successResponse.data.specialization_data.specialization_name;
        this.specialization_key = successResponse.data.specialization_data.specialization_key;
        this.short_description = successResponse.data.specialization_data.description;
        this.old_name = this.specialization_name;
        this.spec_position = successResponse.data.specialization_data.position;
        this.specialist_array = successResponse.data.all_specializations_list;

        this.specialization_name_mm = successResponse.data.specialization_data.specialization_name_mm;
        this.short_description_mm = successResponse.data.specialization_data.description_mm;
      })
      .catch((errorResponse) => {
        handleError(errorResponse, this.$router, 'adminEditSpecialization', 'token');
      });
    }
  },
  components: {
    NavBar,SideBar
  },
  methods: {
    redirectToViewAllSpecializations()
    {
     this.$router.push({
       name: "ViewSpecialization",
     });
   },
 editSpecialization()
 {
  this.isSpecialisationClicked = true
  let x = this.specialist_array.find(o => o.specialization_name.trim() ===  this.specialization_name);
  if(x && x.specialization_name != this.old_name)
  {
    alert("Specialization with this name already exists");
  }
  else
  {
    var specializationEditbody = {
      "specializationId" : this.specializationId,
      "specializationName" : this.specialization_name.trim(),
      "specializationName_mm" : this.specialization_name_mm.trim(),
      "description" : this.short_description,
      "description_mm" : this.short_description_mm,
      "token" : this.currentUser,
      "typeOfUser" : "ADMIN",
      "nameChanged": this.old_name.trim() != this.specialization_name.trim() ? true: false,
      position: this.spec_position
    }
    axios.post(process.env.VUE_APP_BACKEND_URL+"/modifySpecialization",specializationEditbody)
    .then((modifySpecializationResponse) =>{
      alert("Specialization edited");
      this.isSpecialisationClicked = false
      this.redirectToViewAllSpecializations()
    })
    .catch((modifySpecializationError) => {
      this.isSpecialisationClicked = false
      if(modifySpecializationError.response.status == 401){

        window.alert("Sorry !! You are unauthorized !!");
        this.$router.push({
          name : "AdminLogin"
        });
      }
      else if(modifySpecializationError.response.status == 403){
        window.alert("Please provide all the required fields");
      }
      else{
        window.alert("Something went wrong...");
      }
    });
  }
},
}
}
</script>

<style>

td,
th {
	padding:10px;
}
#facebook{
  display: none;
}
</style>